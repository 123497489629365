.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body{
  margin: 0px;
  overflow-x: hidden;
}

.loading{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: red !important;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading_title{
  font: 1rem 'Noto Sans KR';
  text-align: center;
  color:white;
}

/* logo's */
#login_logo{
  width:155px;
}
.epr_main{
  max-width: 550px;
  padding: 50px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.epr_main_b{
  height: 100vh;
  background-color: black;
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.epr_main h3{
  margin: 30px 0;
}
.epr_main #info{
  color: #444444;
  font-size: 15px;
}


.epr_main #logo, .epr_main_b #logo{
  width: 150px;
}

.m_left{
  align-items: left;
}
.m_comment_box{
  border: 1px solid #a1a1a1;
  border-radius: 4px;
  width:90%;
  min-height: 100px;
  padding: 10px;
  margin-bottom: 30px;
  box-shadow:  0 0 19px rgba(0,0,0,0.10);

}

.comment_area{
  max-width:500px
}

.epr_mobile{
  margin-top: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 400px;
  padding: 0 20px;
}

.epr_mobile .epr_btn{
  width:300px
}
.epr_mobile img{
margin: 0 20px;
max-width: 350px; 
}

.m_search_form{
  margin: 0 20px;
  margin-top: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 10px;
  width: 90%;
  align-items: center;
}

.m_search_info{
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 300px;
  font-size: 16px;
  &[type="submit"] {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  } 
}

.search_info{
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 300px;
  font-size: 14px;
  
}

.m_recommand{
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;

  /* box-shadow:  0 0 19px rgba(0,0,0,0.10); */
  border-radius: 20px;
}

.result_recommand{
  box-shadow:  0 0 19px rgba(0,0,0,0.10);
  border-radius: 20px;
  margin-top: 20px;
  align-items: center;

}
.result_title{
  font-size: 16px;
  font-weight: 800;
  margin : 30px 0;
  text-transform: uppercase;
}
.result_img{
    width: 80%;
  }

.upload_btn{
  border-radius: 18px;
  padding : 10px 30px;
  font-size: 14px;

  background-color: #D53346;
  align-items: center;
  text-align: center;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;  
  margin: 20px 0;
}

.epr_btn{
  padding: 15px 20px;
  background-color: #D53346;
  align-items: center;
  text-align: center;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &[type="submit"] {
    margin-top: 10px;
    }
  }





  #fileplz{
    background-color: #f5f5f5;
    border-radius: 30px;
    border : none;
    padding: 15px 30px;
    font-size: 15px;
    margin-top: 20px;
  }


/* admin */
.epr_admin #logo, .admin_register #logo{
  width: 155px;
  margin: 60px 0;
  cursor: pointer;

}

.epr_admin {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

#toolbar{
  margin: 40px 0;
}

#data_table{
  border-top: 1.5px solid black;
  width: 90%;
}

#data_table tr{
  border: 1px solid !important;
  border-color: black;
}

#head th{
  font-size: 14px;
  font-weight: 350;
  padding: 10px 0;

}

#tbody td{
  padding: 10px 0 !important;
}

#data_body{
  border-bottom: 1px solid black;
}

#search{
  border: 1px solid black;
  border-radius: 18px;
  padding : 10px 30px;
  font-size: 14px;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;

}

#register{
  border: none;
  border-radius: 18px;
  padding : 10px 30px;
  font-size: 14px;
  color: white;
  background-color: black;
  margin-left: 10px;
  cursor: pointer;

}

.align-right{
  display: flex;
}
#delete{
  font-size: 14px;
  color: white;
  margin-left: auto;
  background-color: black;
  border: none;
  border-radius: 18px;
  padding : 5px 15px;
  cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
}


#search_key{
  cursor: pointer;
}
#search_key:hover{
  text-decoration: underline;
}



/* register */
.admin_register{
  background-color: #f8f8f8;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

#fitting_analy{
  display: inline-block;
  position: relative;
  align-items: left;
  padding: 30px 50px;
  background-color: white;
  width: 80%;
  max-width:800px;
  border-radius: 30px;
  box-shadow:  0 0 19px rgba(0,0,0,0.10);
  margin-bottom: 100px;
}

#fitting_analy .title{
  font-weight: 800;
  text-align: left;
  font-size: 20px;
  margin-top: 70px;
  display: flex;
}

.fitting_area{
  display: inline-block;
  position: relative;
  margin-top:30px;
}
.fitting_area .fitting_title{
  text-align: left;
}

.inputSet{
  display: flex;
}

.regigister_input{
  width:100%;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  font-size: 14px;
}
#input_name_wrap{
  width:35%;
  margin-right:5%;
}
#input_phone_wrap{
  width:55%;
}

.input_info{
  padding: 10px 0px 10px 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  font-size: 14px;
  width:100%
}



fieldset{
  border: none !important;
  padding-left: 0 !important;
  text-align: start !important;
}

fieldset label{
  margin-right: 15px !important;
}

.comment_area textarea{
  max-width:500px;
  min-height: 200px;
}

#logout{
  background-color: white;
  border: none;
  cursor: pointer;
  margin: 20px 5% 20px auto;
}

#logout:hover{text-decoration: underline;}

#file_area{
  width:100%;
  display: flex;
}
#file_area input{
  margin-left: 0 !important;
}